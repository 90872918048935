<av-card [attr.aria-label]="'Disclaimer' | translate" [backdrop]="false">
  <av-card-body [noHeader]="true">
    <div [innerHTML]="text"></div>
  </av-card-body>
  <av-card-footer class="fx-row fx-justify-space-between">
    <button avButton (click)="exit()" translate data-tid="disclaimer/exit">
      Exit
    </button>
    <button
      avButton
      (click)="accept()"
      color="accent"
      translate
      data-tid="disclaimer/accept"
      #acceptRef
    >
      Agree & Continue
    </button>
  </av-card-footer>
</av-card>
