<av-tooltip
  class="network-indicator"
  [position]="position"
  *ngIf="isVisible"
  [@zoomInOut]="true"
>
  <div
    [attr.aria-label]="
      'Network quality is ' + (isGood ? 'good' : isLow ? 'low' : 'bad') | translate
    "
    class="bars fx-row fx-align-end fx-justify-center"
    [ngClass]="{
      'network-good': isGood,
      'network-low': isLow,
      'network-bad': isBad
    }"
  >
    <div></div>
    <div></div>
    <div></div>
  </div>
  <ng-container tooltip-message>
    <div>
      <b translate>Network Indicator</b>
    </div>
    <div class="network-indicator-stats">
      <div><small class="text-muted">Jitter</small> {{ jitter }},</div>
      <div>
        <small class="text-muted">Packet Loss</small> {{ packetLoss }}%,
      </div>
      <div><small class="text-muted">Round-trip Time</small> {{ rtt }}</div>
    </div>
  </ng-container>
  <!-- <ng-container *ngIf="!isDebug">
    <span class="tooltip tooltip-center tooltip-bottom" *ngIf="isGood" translate>
      Call quality appears to be optimal
    </span>
    <span class="tooltip tooltip-center tooltip-bottom" *ngIf="isLow" translate>
      Call quality appears to be low
    </span>
    <span class="tooltip tooltip-center tooltip-bottom" *ngIf="isBad" translate>
      Call quality appears to be bad
    </span>
  </ng-container> -->
</av-tooltip>

<!-- <div class="network-indicator-local" [@slideFromUp]="true" *ngIf="isVisible"
  [ngClass]="{'network-low':isLow, 'network-bad':isBad}">
  <div class="fx-row fx-align-center fx-justify-center">
    <div class="label" translate>My network quality</div>
    <div *ngIf="isGood" translate>Good</div>
    <div *ngIf="isLow" translate>Low</div>
    <div *ngIf="isBad" translate>Bad</div>
  </div>
  <span class="tooltip tooltip-center tooltip-bottom" *ngIf="isDebug">
    jitter: {{quality.averageJitter}},
    network: {{quality.averageNetworkQuality}},
    rtt: {{quality.averageRtt}}
  </span>
  <ng-container *ngIf="!isDebug">
    <span class="tooltip tooltip-center tooltip-bottom" *ngIf="isLow" translate>
      Network quality low
    </span>
    <span class="tooltip tooltip-center tooltip-bottom" *ngIf="isBad" translate>
      Network quality Bad. Try closing your camera
    </span>
  </ng-container>
</div> -->
