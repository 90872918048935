import { Component, ElementRef, OnInit, Renderer2 } from "@angular/core";
import { ActivityIndicatorService } from "../../services/activity-indicator.service";
import { fadeOut, zoomInOut } from "../../core-ui.animations";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-activity-indicator",
  templateUrl: "./activity-indicator.component.html",
  styleUrls: ["./activity-indicator.component.scss"],
  animations: [zoomInOut, fadeOut],
})
export class ActivityIndicatorComponent implements OnInit {
  visible: boolean;
  message: string;
  icon: string;
  backdropVisible: boolean;

  constructor(
    private activity: ActivityIndicatorService,
    private host: ElementRef,
    private renderer: Renderer2,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.activity.message$.subscribe((val) => {
      this.message = val;
      if (this.message)
        this.renderer.setAttribute(
          this.host.nativeElement,
          "aria-label",
          this.translate.instant(this.message)
        );
    });
    this.activity.isVisible$.subscribe((val) => {
      this.visible = val;
      if (this.visible) {
        this.renderer.setAttribute(this.host.nativeElement, "role", "alert");
      } else {
        this.renderer.removeAttribute(this.host.nativeElement, "role");
        this.renderer.removeAttribute(this.host.nativeElement, "aria-label");
      }
    });
    this.activity.icon$.subscribe((val) => (this.icon = val));
    this.activity.backdrop$.subscribe((val) => (this.backdropVisible = val));
  }
}
