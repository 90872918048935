<div class="container container-responsive">
  <h1 class="title" translate>Monitor an interaction</h1>
  <h3 translate>
    Join an interaction discreetly, remaining unseen and unheard by both the
    agent and the customer.
  </h3>
  <av-card [backdrop]="false">
    <av-card-body [noHeader]="true" type="thin">
      <form class="fx-row fx-gap-20">
        <input
          avInput
          id="interaction-id"
          type="text"
          placeholder="Interaction ID"
          name="interactionid"
          class="fx-flex-4"
          required
          [(ngModel)]="interactionId"
        />
        <button
          class="fx-flex-1"
          avButton
          color="accent"
          (click)="joinInteraction()"
          [loading]="isLoading()"
          [disabled]="isLoading()"
          translate
        >
          Monitor this interaction
        </button>
      </form>
    </av-card-body>
  </av-card>
</div>
